<script setup lang="ts">
import { onMounted } from 'vue'
import { useProvider } from '/~/composables/provider'
import PayToRowDesk from '/~/templates/bill-payments/views/payments/payees/components/pay-to-row.desk.vue'
import PaymentPayeesListDesk from './payments-payees-list.desk.vue'
import PaymentPayeesSearchDesk from './payments-payees-search.desk.vue'
import { usePaymentsPayeesComponent } from './use-payments-payees-component'

const { searchString, isEmpty, initPayeeList } = usePaymentsPayeesComponent()
const { isBillPaymentsV15Template } = useProvider()

function onSearch(value: string): void {
  searchString.value = value
}

onMounted(() => {
  initPayeeList()
})
</script>

<template>
  <div
    class="flex h-full w-full flex-col overflow-hidden bg-eonx-neutral-50"
    :class="{
      'rounded-t-3xl': !isBillPaymentsV15Template,
    }"
  >
    <div
      v-if="!isEmpty"
      class="mb-8 flex flex-col items-start sm:flex-row sm:items-center"
    >
      <h2
        class="mr-[30px] shrink-0 text-2xl font-bold text-eonx-neutral-800 sm:mb-0"
      >
        Payee list
      </h2>
    </div>

    <payment-payees-search-desk v-if="!isEmpty" @search="onSearch" />
    <payment-payees-list-desk :search-string="searchString">
      <template #payeeSlot="{ payee }">
        <pay-to-row-desk
          :payee="payee"
          class="rounded-lg bg-white"
          :enable-context-menu="true"
        />
      </template>
    </payment-payees-list-desk>
  </div>
</template>
