<script setup lang="ts">
import { onMounted } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useAccess } from '/~/composables/access/use-access'
import PayToRowMobile from './components/pay-to-row.mobile.vue'
import PaymentPayeesListMobile from './payments-payees-list.mobile.vue'
import { usePaymentsPayeesComponent } from './use-payments-payees-component'

const {
  searchString,
  enabledAddNewButton,
  handleAddNewPayeeClick,
  initPayeeList,
} = usePaymentsPayeesComponent()
const { withAccessCheck, isCheckingIdentityVerification } = useAccess()

function actionFunction() {
  withAccessCheck({
    callback: handleAddNewPayeeClick,
    config: { actionType: 'payees' },
  })
}

onMounted(() => {
  initPayeeList()
})
</script>

<template>
  <base-aside-page
    title="Payees"
    :no-padding="true"
    :back="{ name: 'home' }"
    :action="enabledAddNewButton ? actionFunction : undefined"
    :is-action-loading="isCheckingIdentityVerification"
  >
    <div>
      <base-input
        v-model="searchString"
        placeholder="Search payees"
        look="rounded"
        icon="plain/search"
        icon-plain
        class="mx-5"
      />
      <payment-payees-list-mobile class="px-5" :search-string="searchString">
        <template #payeeSlot="{ payee }">
          <pay-to-row-mobile
            :payee="payee"
            class="p-2.5"
            :enable-context-menu="true"
          />
        </template>
      </payment-payees-list-mobile>
    </div>
  </base-aside-page>
</template>
